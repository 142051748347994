import React, { useState, useEffect } from 'react';

import Meta from '../components/meta';

import { withPrefix } from "gatsby"

import { StaticImage } from "gatsby-plugin-image";

import { Link } from 'gatsby';

import Contents from '../components/contents';

import ReactVivus from 'react-vivus';

import InlineLogo from '../svg/inline.svg';



const Recruit = () =>{



    const outlineSvg = withPrefix('/') + 'outline.svg'

    

    const [animShow, setAnimShow] = useState(true);
    const [animStat, setAnim] = useState(false);

    useEffect(() => {

        if(animStat === true){
            document.getElementsByClassName('js-logo-outline')[0].classList.add('is-hide')
            document.getElementsByClassName('js-logo-anim')[0].classList.remove('is-hide')
        }

        return () => { setAnimShow(false) };
    },[animStat]);

    const loadLogo = () =>{
        setAnim(true)
    }



    return(
        <>

            <Meta
                title="採用情報"
                description="ニッチトップを共有するメンバー"
            />

            <Contents>

                <div className="c-sub-bg is-recruit">

                    <div className="c-sub-header is-recruit">
                        <em className="c-sub-header--eng u-josef">
                        RECRUIT
                        </em>

                        <h1 className="c-sub-header--title">
                        ニッチトップを共有するメンバー
                        </h1>
                    </div>

                </div>



                <div className="p-recruit-wrap">
                    <div className="p-recruit-nav">
                        <nav className="p-recruit-nav-list">

                            <Link to="/recruit/jobs/" title="" className="p-recruit-nav-btn is-job">
                                <div className="p-recruit-nav-btn-cnt">
                                    <p className="p-recruit-nav-btn-cnt--title">
                                    募集職種
                                    </p>

                                    <p className="p-recruit-nav-btn-cnt--text">
                                    あなたの発想で<br />
                                    しくみを創る
                                    </p>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_job.png"
                                    alt="募集職種"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                            <Link to="/recruit/culture" title="" className="p-recruit-nav-btn is-welfare">
                                <div className="p-recruit-nav-btn-cnt">
                                    <p className="p-recruit-nav-btn-cnt--title">
                                    成長支援
                                    </p>

                                    <p className="p-recruit-nav-btn-cnt--text">
                                    フレキシブルに<br />
                                    仕事を楽しむ
                                    </p>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_welfare.png"
                                    alt="成長支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                            <Link to="/solution" title="" className="p-recruit-nav-btn is-business is-single">
                                <div className="p-recruit-nav-btn-cnt">
                                    <div className="p-recruit-nav-btn-cnt-inner u-align-center">
                                        <p className="p-recruit-nav-btn-cnt--title">
                                        事業内容
                                        </p>

                                        <p className="p-recruit-nav-btn-cnt--text">
                                        「受発注業務を変える。」を根幹に<br />様々な事業を展開
                                        </p>
                                    </div>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_business.png"
                                    alt="事業内容"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                        </nav>
                    </div>



                    <div className="p-recruit-hero">
                        <div className="p-recruit-hero-inner">

                            <Link to="/" title="" className="p-recruit-hero-box">

                                <div className="p-recruit-hero-box-inner">
                                    {/* <p className="p-recruit-hero-box--text u-align-center">
                                        アクロスソリューションズを知る
                                    </p> */}

                                    <div className="p-recruit-hero-logo-wrap">
                                        {animShow ? <ReactVivus
                                            id="logo"
                                            className="p-recruit-hero-logo is-outline js-logo-outline"
                                            option={{
                                            file:outlineSvg,
                                            duration:100,
                                            animTimingFunction: "EASE",
                                            type: "oneByOne",
                                            }}
                                            callback={
                                                loadLogo
                                            }
                                        />
                                        : null}

                                        <InlineLogo className="p-recruit-hero-logo is-hide js-logo-anim" />
                                    </div>
                                </div>

                            </Link>

                        </div>
                    </div>



                    <div className="p-recruit">

                        <div className="p-recruit-box">
                            <h2 className="p-recruit-box--title">
                            多様な個性が集まる、創造的なチーム
                            </h2>

                            <p className="p-recruit-box--text">
                            アクロスソリューションズはパッケージ製品である<br />
                            BtoB受発注システム「MOS」をお客様に提供し、<br />
                            業務のしくみを変えるお手伝いをしています。<br />
                            <br /><span class="is-sp-br"></span>
                            おかげ様で業種業界を問わずさまざまな企業様から<br />
                            お問い合わせをいただいております。<br />
                            <br /><span class="is-sp-br"></span>
                            お客様と直接お会いして改善提案活動を行う<b>アカウント営業</b>、<br />
                            販売パートナーと連携して代理店顧客に改善提案活動を行う<b>パートナー営業</b>、<br />
                            MOSを通じてお客様の仕組みに沿ったカスタマイズを行う<b>ソリューションエンジニア</b>、<br />
                            MOSを利用するお客様の利便性や満足感を高めるために最先端の技術や<br />
                            ノウハウを元に製品開発を行う<b>パッケージ開発エンジニア</b><br />
                            MOSを導入した後にMOSの活用促進を図る<b>カスタマーサクセス営業</b>、<br />
                            <br /><span class="is-sp-br"></span>
                            私たちは、こうした仲間との出会いを心待ちにしています。<br />
                            <br /><span class="is-sp-br"></span>
                            アクロスソリューションズはこれからも成長を続けていきます。<br />
                            会社の成長の源泉は社員ひとりひとりが成長し続けてくれることだと考えています。<br />
                            そのために社員が成長できる環境を更に向上させ、少しずつ体制を整えていきます。<br />
                            <br /><span class="is-sp-br"></span>
                            当社はまだ小規模かもしれませんが、社員がイキイキと働くことで、<br />
                            お客様にもご満足いただけると信じています。<br />
                            <br /><span class="is-sp-br"></span>
                            まずはお話ししてみませんか。<br />
                            ご応募お待ちしております。<br />
                            </p>

                            <p className="p-recruit-box--sign u-align-right">
                            株式会社アクロスソリューションズ<br />
                            代表取締役社長 能登 満
                            </p>
                        </div>

                        <div className="p-recruit-entry">
                            <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                            採用について問い合わせる
                            </Link>
                        </div>

                    </div>
                </div>

            </Contents>

        </>
    )
}

export default Recruit;


